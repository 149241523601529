import * as React from 'react'
import SmallBanner from '../components/SmallBanner'
import Container from '../components/Container'
import styled from 'styled-components'
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { breakpoint, colors } from '../styles/variables'
import { Link } from 'gatsby'


const Wrapper = styled(Container)`
  min-height: 100vh;
  margin-top: 2rem;
  margin-bottom: 3rem;
  max-width: 700px;
  padding: 0 1rem 2rem;

  @media ${breakpoint.sm} {
    margin-top: 3rem;
  }

  @media ${breakpoint.md} {
    margin-top: 4.5rem;
    margin-bottom: 5rem;
  }

  @media ${breakpoint.lg} {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }
`

const Title = styled.h2 `
    margin-top: 0;
    text-transform: none;
    margin-bottom: 0.5em;
    font-size: 1.75rem;
`

const StyledFigure = styled.figure `
    margin: 1.5rem 0 2rem;

    @media ${breakpoint.md} {
        margin: 2rem 0 2.5rem;
    }
`

const StyledFigcaption = styled.figcaption `
    font-size: 0.875rem;
    color: gray;
    margin-top: 0.5em;
    text-align: right;
`

const BackLink = styled(Link)`
  text-decoration: none;
  color: ${colors.primary};
  margin-bottom: 2.5rem;
  display: inline-block;
  font-size: 0.875rem;
  transition: color 0.3s ease;

  @media ${breakpoint.lg} {
    margin-bottom: 4rem;
  }

  &:hover {
    color: ${colors.orangeHover};
  }

  .icon {
    margin-right: 0.5rem;
  }
`

const Date = styled.p`
    color: gray;
    margin: 0 0 2rem;
    font-size: 0.875rem;
`

const BlogpostPage = ({data, title}) => {

    return (
       <>
        <SmallBanner title="Blog"/>
        <Wrapper>
            <BackLink to="/blog">
                <span className="icon icon-arrow-left"></span>
                Zpět na výpis článků
            </BackLink>
            <Title>Kambodža - do třetice všeho dobrého</Title>
            <Date>14. 11. 2022</Date>
            <p>Po získání zapomenutých dokladů jsme se podle plánu přesunuli na ostrov Koh Rong. Brzy jsme zjistili, že kromě aktivit v moři a odpočinku se nedá nic jiného dělat. Což pro mě vzhledem ke stavu nohy znamenalo pouze odpočinek.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia3.jpg" alt="Koh Rong, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: ostrov Koh Rong - Coconut Beach, Cambodia)</StyledFigcaption>
            </StyledFigure>
            <p>Abych blíže nastínila situaci na ostrovech: jsou místa, která jsou turistická a je tam spoustu barů, obchodů a všude hodně lidí. My jsme zvolili méně turistickou oblast, tj. žádné bary, jeden obchod a málo lidí. Zní to skvěle, pokud zrovna nemáte nemohoucí nohu a zákaz aktivit jako je plavání, potápění, rybaření, večerní výlety za planktonem… Už po dvou dnech jsem měla odpočinku dost.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia4.jpg" alt="Koh Rong, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: ostrov Koh Rong - Coconut Beach, Cambodia)</StyledFigcaption>
            </StyledFigure>
            <p>Třetí den se kamarád Jirka rozhodl, že si půjčíme od majitelů ubytování skútr a vydáme se vnitrozemím na druhou stranu ostrova. Skútr, to tady dlouho nebylo. Naštěstí jsem tentokrát neřídila, což jsem ocenila vzhledem k tomu, že nikde nebyly silnice, pouze zpevněné cesty.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia5.jpg" alt="Koh Rong, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: ostrov Koh Rong - někde v džungli, Cambodia)</StyledFigcaption>
            </StyledFigure>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia6.jpg" alt="Koh Rong, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: ostrov Koh Rong - někde v džungli, Cambodia)</StyledFigcaption>
            </StyledFigure>
            <p>Kvůli mé dopolední návštěvě nemocnice jsme vyrazili na výlet o něco později. Cesta byla úchvatná! Žádné davy lidí, jen pár místních obyvatel v chatrčích a úžasná příroda všude kolem. Vše bylo v pořádku, dokud jsme nedorazili před poslední kopec, který vedl dolů k pláži. Úsek byl kamenitý a písčitý. Jakožto zodpovědný spolujezdec jsem nabídla, že ten kousek sejdu pěšky.</p>
            <p><i>„Ne, to je v pohodě.“<br />„Seš si jistej?“<br />„No jasný, neměj strach.</i></p>
            <p>Pár vteřin na to jsme leželi pod skútrem. Tedy Jířa jakžtakž stál na nohou a já ležela pod skútrem. Naštěstí jsem pod ním měla zdravou nohu. Vstala jsem, oprášila se od písku a kamínků a opláchla si odřeniny. Mé nohy vypadaly jako nohy fotbalisty, ale hlavně, že jsem je pořád měla.</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia7.jpg" alt=""></StaticImage>
                <StyledFigcaption>(Photo by Julia: nohy zraněného fotbalisty :-D)</StyledFigcaption>
            </StyledFigure>
            <p>Na pláži jsme se sešli s novými přáteli, se kterými jsme se seznámili pár dní před tím. Dali jsme si večeři a chvíli si povídali. Najednou jsme si všimli, že se začíná zatahovat a je docela pozdě – čas vyrazit.</p>
            <p>První kopec nahoru jsme úspěšně zdolali a zničehonic nás obklopila černočerná tma. Nějak nám nedošlo, že cestu džunglí nelemují lampy a světlo skútru sotva svítilo. Mohla to být romantika jako vyšitá, ale to by nesměl začít slejvák. Lilo jako když někdo naplno povolí kohoutek. Hromy burácely jak divé a blesky rozsvěcovaly celou oblohu. Po několika uklouznutích a spadnutích jsme byli dost vyřízení. Dohodli jsme se, že až uvidíme první obydlí, jdeme tam.</p>
            <p>Po chvilce přišla záchrana – přístřešek, ze kterého na nás mával chlapík. Neváhali jsme ani vteřinu. Usadili jsme se a čekali, až se déšť trošku uklidní. Pan domácí odešel a vrátil se se dvěma plechovkami vychlazeného piva, které opravdu bodly. Začali jsme vymýšlet, kde se natáhneme a přečkáme do rána, protože jsem byla rozhodnutá, že v tomhle už jet nechci. Zítra ráno je taky den. </p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia8.jpg" alt="Koh Rong, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: někde v chatrči uprostřed džungle, Koh Rong, Cambodia)</StyledFigcaption>
            </StyledFigure>
            <p>Naštěstí se po hodině počasí uklidnilo a my mohli vyrazit. Zbývalo nám posledních 15 minut cesty, která už byla na pohodu. Sice jsme přejeli odbočku a museli se kus vracet, ale to byl malý detail ve srovnání s předchozími zážitky. </p>
            <p>Ačkoliv to místy bylo dost náročné a děsivé, shodli jsme se, že to bylo super. Déšť, mokro, blesky, hromy, štěkající psi ve tmě, všude bahno… Stálo to za to! Zbytek večera jsme seděli na pláži a vstřebávali dobrodružné odpoledne. </p>
            <p>Kdyby se mě někdo zeptal, jestli bych do toho šla znovu? Ano – klidně třikrát. :-)</p>
            <StyledFigure>
                <StaticImage src="../assets/images/blog/cambodia9.jpg" alt="Koh Rong, Coconut beach, Cambodia"></StaticImage>
                <StyledFigcaption>(Photo by Julia: ostrov Koh Rong, Coconut beach, Cambodia)</StyledFigcaption>
            </StyledFigure>
        </Wrapper>
       </>
    )
  }

  export default BlogpostPage
